<template>
  <div class="main-holder">
    <v-row v-if="contractType !== null" align="start" justify="center">
      <v-col cols="12">
        <div class="d-flex flex-row ml-2 pl-2 justify-space-between">
          <div>
            <h2 class="grey--text font-weight-regular">
              {{ contractTypeName }}
            </h2>
          </div>
          <v-btn @click="deleteContract" depressed color="red white--text">
            DELETE CONTRACT
            <v-icon class="ms-2"> mdi-trash-can </v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-if="contractType != fixedUsdContract.value"
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <div class="d-flex flex-row align-start justify-space-start ml-2 pl-2">
          <div class="me-10 d-flex flex-column justify-start align-start">
            <div class="pt-1">
              <span
                class="overline mr-2"
                :class="{ 'font-weight-light': !isOptionsActive }"
              >
                <v-icon
                  :class="{ 'success--text': !!isOptionsActive }"
                  class="mr-2 pb-1"
                  >mdi-barn</v-icon
                >Quintales / Hectáreas
              </span>
            </div>
            <div class="d-flex flex-row align-center mt-1">
              <v-text-field
                class=""
                :readonly="!isOptionsActive"
                :filled="!isOptionsActive"
                outlined
                dense
                type="number"
                v-model.number="rentQqHa"
                label="qq/ha"
              />
              <v-icon
                class="pb-6 ms-2"
                v-if="totalPlanned != rentQqHa"
                color="warning"
              >
                mdi-alert-decagram-outline
              </v-icon>
            </div>
          </div>

          <div class="me-10 d-flex flex-column justify-start align-start">
            <div class="pt-1">
              <span
                class="overline mr-2"
                :class="{ 'font-weight-light': !isOptionsActive }"
              >
                <v-icon
                  :class="{ 'success--text': !!isOptionsActive }"
                  class="mr-2 pb-1"
                  >mdi-silo-outline</v-icon
                >Grano a tomar
              </span>
            </div>
            <div class="d-flex flex-row justify-start mt-1 pt-0">
              <template v-if="rentActivities">
                <v-chip
                  v-for="activity in rentActivities"
                  :key="activity.id"
                  class="me-2"
                  :class="{
                    'white--text selected font-weight-bold':
                      activity.id == selectedSeedId,
                    'grey--text text--dark font-weight-bold':
                      activity.id != selectedSeedId,
                  }"
                  :disabled="isActivityDisabled(activity)"
                  @click="setSelectedSeedId(activity.id)"
                >
                  {{ activity.name }}
                </v-chip>
              </template>
            </div>
          </div>

          <div class="me-10 pt-1 d-flex flex-column justify-start align-start">
            <span
              class="overline mr-2"
              :class="{ 'font-weight-light': !isOptionsActive }"
            >
              <v-icon
                :class="{ 'success--text': !!isOptionsActive }"
                class="mr-2 pb-1"
                >mdi-seed-plus</v-icon
              >Precio de Grano
            </span>
            <v-text-field
              class=""
              :readonly="!isOptionsActive"
              :filled="!isOptionsActive"
              outlined
              dense
              type="number"
              v-model.number="selectedSeedPrice"
              label="$"
            />
          </div>
          <div
            class="me-3 d-flex flex-row align-center justify-space-center mt-5 pt-6"
          >
            <v-btn
              depressed
              v-if="isOptionsActive"
              color="primary white--text"
              class="mr-2"
              @click="saveRentConfig"
            >
              <v-icon> mdi-floppy </v-icon>
            </v-btn>

            <v-btn
              :depressed="true"
              v-if="isOptionsActive"
              @click="exitRentConfig"
              color="info white--text"
              class="mr-2"
            >
              Atrás
            </v-btn>
            <v-btn
              :depressed="true"
              color="warning white--text"
              class="mr-2"
              elevation="0"
              v-if="!isOptionsActive"
              @click="enterRentConfig"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <div class="d-flex flex-row justify-space-between">
          <div>
            <h2>Seleccionar Contrato</h2>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="contract == null && !showBonusOptions"
      align="center"
      justify="center"
    >
      <!-- FIX QQ -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-btn
          block
          variant="tonal"
          class=""
          color="secondary"
          @click="showBonusOptions = true"
        >
          <span class="">
            <v-icon> {{ fixedQqContract.icon }} </v-icon>
            {{ fixedQqContract.name }}</span
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="contract == null && showBonusOptions"
      align="center"
      justify="center"
    >
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-btn
          v-if="showBonusOptions"
          @click="saveNewFixQqContract(fixedQqContract, false, null)"
          block
          variant="tonal"
          color="info"
        >
          Sin Bonificaciones
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-btn
          @click="saveNewFixQqContract(fixedQqContract, true, false)"
          block
          :disabled="draftEngagementActivitySlices.length == 0"
          variant="tonal"
          color="info"
        >
          Kg / Ha
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-btn
          v-if="showBonusOptions"
          @click="saveNewFixQqContract(fixedQqContract, true, true)"
          block
          :disabled="draftEngagementActivitySlices.length == 0"
          variant="tonal"
          color="info"
        >
          USD / Ha
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="contract == null" align="center" justify="center">
      <!-- FIX USD -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <div>
          <v-btn
            block
            variant="tonal"
            class=""
            color="secondary"
            @click="saveNewFixUsdContract(fixedUsdContract)"
          >
            <span class="">
              <v-icon> {{ fixedUsdContract.icon }} </v-icon>
              {{ fixedUsdContract.name }}</span
            >
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row style="" v-else-if="contract.value == fixedQqContract.value">
      <v-col
        ><AbstractFixQuintals
          v-if="singleDraftEngagement"
          :parsedDefaultDate="parsedDefaultDate"
          @saveNewPlannedQuintals="saveNewPlannedQuintals"
          @saveEditPlannedQuintals="saveEditPlannedQuintals"
          @deletePlannedQuintals="deletePlannedQuintals"
          @saveNewFixedQuintals="saveNewFixedQuintals"
          @saveEditFixedQuintals="saveEditFixedQuintals"
          @deleteFixedQuintals="deleteFixedQuintals"
          @saveEditBonus="saveEditBonus"
          @saveNewBonus="saveNewBonus"
          @deleteBonus="deleteBonus"
          @addKgBonuses="saveNewFixQqContract(fixedQqContract, true, false)"
          @addUsdBonuses="saveNewFixQqContract(fixedQqContract, true, true)"
          :physicalHectares="physicalHectares"
          :rentPaymentPlan="singleDraftEngagement.rentPaymentPlan"
          :rentFixedRows="singleDraftEngagement.rentFixedRows"
          :rentBonuses="singleDraftEngagement.rentBonuses"
          :rentQqHa="singleDraftEngagement.rentQqHa"
          :rentQqActivityPrice="singleDraftEngagement.rentQqActivityPrice"
          :draftEngagementActivitySlices="
            draftEngagementActivitySlices
          " /></v-col
    ></v-row>
    <v-row v-else-if="contract.value == fixedUsdContract.value">
      <v-col
        ><AbstractFixUSD
          :parsedDefaultDate="parsedDefaultDate"
          @saveEditPlannedUsd="saveEditPlannedUsd"
          @saveNewFixedUsd="saveNewFixedUsd"
          @saveEditFixedUsd="saveEditFixedUsd"
          @deleteFixedUsd="deleteFixedUsd"
          :rentPaymentPlan="singleDraftEngagement.rentPaymentPlan"
          :rentFixedRows="singleDraftEngagement.rentFixedRows"
          :physicalHectares="physicalHectares" /></v-col
    ></v-row>
    <div class="mb-5"></div>
  </div>
</template>

<script>
import {
  DRAFT_CONTRACTS,
  DRAFT_FIXED_BONUS_QQ_CONTRACT,
  DRAFT_FIXED_USD_CONTRACT,
} from "../../managrx/constants";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../managrx/serverCall";
import AbstractFixQuintals from "../Abstract/Rent/DraftContracts/AbstractFixQuintals.vue";
import AbstractFixUSD from "../Abstract/Rent/DraftContracts/AbstractFixUSD.vue";
export default {
  name: "RentInput",
  components: {
    AbstractFixQuintals,
    AbstractFixUSD,
  },
  props: {
    defaultDate: String,
    physicalHectares: Number,
    singleDraftEngagement: Object,
    rentActivities: Array,
    draftEngagementActivitySlices: Array,
  },
  computed: {
    contract() {
      return this.contracts.find(
        (el) => el.value == this.singleDraftEngagement?.contractType
      );
    },
    parsedDefaultDate() {
      return this.defaultDate
        ? this.defaultDate?.split("T")?.[0]
        : new Date().toISOString().split("T")[0];
    },
    showContractOptions() {
      return this.showContractOptionsId ? true : false;
    },
    contractType() {
      return this.singleDraftEngagement.contractType;
    },
    contractTypeName() {
      return this.contract?.name;
    },
    totalPlanned() {
      return this.singleDraftEngagement?.rentPaymentPlan
        .map((el) => el.qqHa)
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    rentActivity() {
      if (!this.rentActivities || !this.singleDraftEngagement) return null;
      return this.rentActivities.find(
        (el) => el.id == this.singleDraftEngagement.rentQqActivity
      );
    },
  },
  methods: {
    refreshRent() {
      this.$emit("refreshInput");
    },
    setSelectedSeedId(id) {
      if (this.isOptionsActive) {
        this.selectedSeedId = id;
      }
    },
    exitRentConfig() {
      this.selectedSeedId = this.rentActivity?.id;
      this.rentQqHa = this.singleDraftEngagement?.rentQqHa;
      this.selectedSeedPrice = this.singleDraftEngagement?.rentQqActivityPrice;
      this.isOptionsActive = false;
    },
    enterRentConfig() {
      this.selectedSeedId = this.rentActivity?.id;
      this.rentQqHa = this.singleDraftEngagement?.rentQqHa;
      this.selectedSeedPrice = this.singleDraftEngagement?.rentQqActivityPrice;
      this.isOptionsActive = true;
    },
    async saveRentConfig() {
      this.isOptionsActive = false;
      let payload = {
        rentQqHa: this.rentQqHa,
        rentQqActivity: this.selectedSeedId,
        rentQqActivityPrice: this.selectedSeedPrice,
      };
      let draftEngagementEndpoint =
        "/draft_engagement/" + this.singleDraftEngagement?.id;
      let result = await putRequest(draftEngagementEndpoint, payload);
      if (!result.success) {
        this.errorHandler(result);
      } else {
        this.refreshRent();
      }
    },
    async deleteContract() {
      let responseArray = [];
      for (const endpoint of Object.values(this.contract.endpoints)) {
        let finalEndpoint =
          endpoint.endpoint +
          "?q=draft_engagement_id:" +
          this.singleDraftEngagement?.id;
        let rowsResult = await getRequest(finalEndpoint);
        if (!rowsResult?.success) {
          this.$store.dispatch("setNewNotification", rowsResult);
        } else {
          for (const row of rowsResult?.data) {
            responseArray.push(deleteRequest(endpoint.endpoint + `/${row.id}`));
          }
        }
      }
      let draftEngagementEndpoint =
        "/draft_engagement/" + this.singleDraftEngagement?.id;
      responseArray.push(
        putRequest(draftEngagementEndpoint, { contractType: null })
      );

      let resultArray = await Promise.all(responseArray);
      let success = true;
      for (const result of resultArray) {
        if (!result.success) {
          this.$store.dispatch("setNewNotification", result);
          success = false;
        }
      }
      if (success) {
        this.refreshRent();
      }
    },
    saveNewFixQqContract(contract, hasBonus, isUsdHa) {
      if (hasBonus) {
        let optionPayload = {
          endpoint: "/draft_engagement_rent_bonus",
          payloadArray: this.draftEngagementActivitySlices?.map((el) => {
            return {
              draftEngagementId: this.singleDraftEngagement?.id,
              activityId: el?.activityId,
              rentBonusPercent: 0,
              yieldThreshold: 0,
              isUsdHa: isUsdHa,
              bonusDate: el?.harvestDate,
            };
          }),
        };
        this.setContractWithInitOption(contract, optionPayload);
      } else {
        this.setContractType(contract);
      }
    },
    saveNewFixUsdContract(contract) {
      // Adds specific payload to the creation of a Fixed USD contract
      let optionPayload = {
        endpoint: "/draft_engagement_rent_usd_payment_plan",
        payloadArray: [
          {
            draftEngagementId: this.singleDraftEngagement?.id,
            hectares: 0,
            date: new Date().toISOString(),
          },
        ],
      };
      this.setContractWithInitOption(contract, optionPayload);
    },
    setContractWithInitOption(contract, contractOption) {
      // Adds key and values needed to initializate a specific contract
      let contractWithOption = {
        ...contract,
        hasInitOptions: true,
        optionEndpoint: contractOption?.endpoint,
        optionArrayPayload: contractOption?.payloadArray,
      };
      this.setContractType(contractWithOption);
    },
    async setContractType(contract) {
      let contractType = contract?.value;
      let endpoint = "/draft_engagement/" + this.singleDraftEngagement?.id;
      let result = await putRequest(endpoint, { contractType: contractType });
      if (!result.success) {
        this.$store.dispatch("setNewNotification", result);
      } else {
        if (contract?.hasInitOptions && contract?.optionArrayPayload.length) {
          for (const payload of contract?.optionArrayPayload) {
            let optionsResult = await postRequest(
              contract?.optionEndpoint,
              payload
            );
            if (!optionsResult.success) {
              this.$store.dispatch("setNewNotification", result);
            }
          }
        }
        this.refreshRent();
      }
    },

    errorHandler(result) {
      this.$store.dispatch("setNewNotification", result);
    },

    // Planned USD
    async saveEditPlannedUsd(payload) {
      let endpoint = "/draft_engagement_rent_usd_payment_plan/" + payload?.id;
      let result = await putRequest(endpoint, payload?.row);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },

    // Fixed USD
    async saveNewFixedUsd(installment) {
      installment.draftEngagementId = this.singleDraftEngagement?.id;
      let endpoint = "/draft_engagement_rent_usd_fixation";
      let result = await postRequest(endpoint, installment);
      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },
    async saveEditFixedUsd(payload) {
      let endpoint = "/draft_engagement_rent_usd_fixation/" + payload?.id;
      let result = await putRequest(endpoint, payload?.row);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },
    async deleteFixedUsd(id) {
      let endpoint = "/draft_engagement_rent_usd_fixation/" + id;
      let result = await deleteRequest(endpoint);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },

    // Bonus
    async saveNewBonus(payload) {
      payload.draftEngagementId = this.singleDraftEngagement?.id;
      let endpoint = "/draft_engagement_rent_bonus/";
      let result = await postRequest(endpoint, payload);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },
    async saveEditBonus(payload) {
      let endpoint = "/draft_engagement_rent_bonus/" + payload?.id;
      let result = await putRequest(endpoint, payload?.bonus);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },

    async deleteBonus(id) {
      let endpoint = "/draft_engagement_rent_bonus/" + id;
      let result = await deleteRequest(endpoint);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },

    // Planned QQ
    async saveNewPlannedQuintals(installment) {
      installment.draftEngagementId = this.singleDraftEngagement?.id;
      let endpoint = "/draft_engagement_rent_payment_plan";
      let result = await postRequest(endpoint, installment);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },
    async saveEditPlannedQuintals(payload) {
      let endpoint = "/draft_engagement_rent_payment_plan/" + payload?.id;
      let result = await putRequest(endpoint, payload?.installment);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },
    async deletePlannedQuintals(id) {
      let endpoint = "/draft_engagement_rent_payment_plan/" + id;
      let result = await deleteRequest(endpoint);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },

    // Fixed QQ
    async saveNewFixedQuintals(installment) {
      installment.draftEngagementId = this.singleDraftEngagement?.id;
      let endpoint = "/draft_engagement_rent_fixation";
      let result = await postRequest(endpoint, installment);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },
    async saveEditFixedQuintals(payload) {
      let endpoint = "/draft_engagement_rent_fixation/" + payload?.id;
      let result = await putRequest(endpoint, payload?.installment);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },
    async deleteFixedQuintals(id) {
      let endpoint = "/draft_engagement_rent_fixation/" + id;
      let result = await deleteRequest(endpoint);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        // Change by an emit event ot update cashflow
        this.refreshRent();
      }
    },

    selectContract(contract) {
      this.setContractType(contract);
    },

    isActivityDisabled(activity) {
      return (
        !this.isOptionsActive &&
        activity.id != (this.rentActivity?.id || this.selectedSeedId)
      );
    },
  },
  mounted() {
    this.rentQqHa = this.singleDraftEngagement?.rentQqHa || 0;
    this.selectedSeedPrice =
      this.singleDraftEngagement?.rentQqActivityPrice || null;
    this.selectedSeedId = this.rentActivity?.id || this.selectedSeedId;
  },
  data() {
    return {
      selectedSeedId: 18,
      rentQqHa: 0,
      showBonusOptions: false,
      selectedSeedPrice: null,
      isOptionsActive: false,
      showContractOptionsId: null,
      showContract: false,
      contractSelectedOption: null,
      contracts: DRAFT_CONTRACTS,
      fixedUsdContract: DRAFT_FIXED_USD_CONTRACT,
      fixedQqContract: DRAFT_FIXED_BONUS_QQ_CONTRACT,
    };
  },
};
</script>

<style>
.home-info-box:hover {
  color: rgb(59, 189, 59);

  border-radius: 10px;
}
.bg-color-disabled {
  background-color: gray !important;
}
.selected {
  color: white !important;
  background-color: #2196f3 !important;
}
</style>
